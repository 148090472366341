
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































































































































































































[class*='popup--'] {
  @include get-all-space;

  position: fixed;
  z-index: 1000;
  top: 0;
  overflow-y: scroll;
  width: 100%;
  visibility: hidden;
  background: rgba($c-black, 0.5);

  &[class*='--footer'] {
    height: 100vh;
    background: rgba($c-black, 0.2);
  }
}

.popup-wrapper {
  position: relative;

  .popup--footer & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  @include mq($until: s) {
    max-width: none;
    padding: 0 !important;
  }
}

.popup__inner {
  position: relative;
  padding-top: $spacing;
  background: $c-white;

  .popup--footer & {
    padding-top: 0;
  }

  @include mq(m) {
    margin: 0 5rem;
    padding-top: 0;
  }
}

.popup__inner__button {
  position: sticky;
  z-index: 2;
  top: 0;
  right: 0;
  width: fit-content;
  margin-left: auto;
  pointer-events: all;

  button[class*='action--'][class*='--light'] {
    padding: math.div($spacing, 2) $spacing;
    background: $c-white;
    border-radius: 0 0 0 8px;
  }

  .popup--footer & {
    position: absolute;

    button[class*='action--'][class*='--light'] {
      padding: $spacing;
      background: 0;
    }

    ::v-deep .action__label {
      position: absolute;
      display: none;
    }
  }

  @include mq($until: m) {
    padding: 0;

    .popup--footer & {
      button[class*='action--'][class*='--light'] {
        &:hover,
        &:focus {
          color: $c-white;
        }
      }
    }
  }

  @include mq(m) {
    position: absolute;
    margin: 0 $spacing * 2 0 auto;

    button[class*='action--'][class*='--light'] {
      padding: 0;
      border-radius: 0;
    }
  }
}

.popup__inner__page {
  min-height: $dynamic-100vh;

  @include mq($until: m) {
    padding-top: 0;
  }
}

::v-deep .cta-experience-wrapped {
  @include mq(l) {
    padding-left: 0;
  }
}
